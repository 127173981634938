:root {
  --primary-color: #0a1118;
  --primary-color-highlight: #233D54;
  --bs-body-bg: #ffffff;
  --bs-body-font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

a { color: var(--primary-color); }
a:hover { color: var(--primary-color); }

/* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bs-dark); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


/* 
  @media (min-width: 544px)  {}
  @media (min-width: 768px)  {}
  @media (min-width: 992px)  {}
  @media (min-width: 1200px) {}
  @media (min-width: 1400px) {}
  @media (min-width: 1600px) {} 
*/

/*
* Custom Container
*/
.container-custom {
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .container-custom { width: 1140px; }
}

@media (min-width: 1400px) {
  .container-custom { width: 1340px; }
}

@media (min-width: 1600px) {
  .container-custom { width: 1520px; }
}

/*
* Main Header
*/
.header {
  z-index: 2;
}

/*
* Flyout Navigation Bar
*/
#navbar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255,247,231);
  z-index: 4;
}

.dropdown-nav__closeNavBtn {
  position: absolute;
  top: 20px;
  right: 16px;
  background-color: rgbA(255,255,255,0.6);
}

.dropdown-nav__container {
  height: 100vh;
  padding: 0 1rem;
}

/*
* Hero Section
*/
.hero {
  position: relative;
  height: 100vh;
  background-color: var(--primary-color);
  overflow: hidden;
}

@media (min-width: 1400px) {
  .hero__heading {
      font-size: 3.2rem;
  }
}

.hero__video {
  position: absolute;
  top:50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
}

.hero__overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  opacity: 0.5;
  z-index: 1;
}

.hero__content {
  z-index: 1;
}

.hero__content-width {
  max-width: 540px;
}

.hero__scroll-btn {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  z-index: 1;
  color: var(--bs-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.hero__scroll-btn:hover {
  color: var(--bs-light);
  opacity: 0.8;
}

.hero__scroll-btn .fa {
  transition-delay: 0.8s;
  animation: bounce 1s infinite alternate;
}

#arrow_down {
  transition-delay: 0.8s;
  animation: bounce 1s infinite alternate;
  font-size: small;
  margin: 10px;
}

 
@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-10px);
  }
}


/*
* Steps Section
*/
.steps {
  padding-top: 40px;
  padding-bottom: 40px;
}

.steps__section-thumbnail {
  /* height: 240px; */
  
  object-fit: contain;
  margin: 0 auto;
}

.steps__content-width {
  max-width: 456px;
  margin: 0 auto;
}

.steps--background {
  background-color: #f6f1f1;
}

@media (min-width: 544px) {
  .steps {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .steps__section-thumbnail {
    height: auto;
  }
}


/*
* Footer
*/
.footer .border-highlight {
  border-top: 1px solid var(--primary-color-highlight);
}


#bottom_banner {
  background-color: #212529;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}


